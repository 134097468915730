export const LIGHT_MODE = 'light';
export const DARK_MODE = 'dark';

export const DEFAULT_LANDING_PAGE = 'DEFAULT';
export const ATLAS_LANDING_PAGE = 'ATLAS';
export const APP_LANDING_PAGES = [
  { id: DEFAULT_LANDING_PAGE, text: 'Default' },
  { id: ATLAS_LANDING_PAGE, text: 'Atlas' },
];
export const AuditFields = {
  CREATED_ON: 'createdOn',
  CREATED_BY: 'createdBy',
  MODIFY_ON: 'modifiedOn',
  MODIFY_BY: 'modifiedBy',
};
export const Colors = {
  ErrorColor: '#FF0000',
  AlertColor: '#cc3300',
  WarningColor: '#ffcc00',
  SuccessColor: '#339900',
  Primary_DeepNavy: '#001e62',
  Primary_Brightblue: '#328FFF',
  Primary_Lightblue: '#93dfe3',
  Primary_TrueTeal: '#2bffc1',
  Primary_ElectricGreen: '#2bffc1',
  Primary_OrangeHighlight: '#ff5c00',
  Primary_GoldenHighlight: '#FFA800',
  Primary_Outlinegray: '#A7A7A7',
  Primary_CaptionGray: '#595959',
  Primary_Pink: '#e121a2',
  Primary_LightPink: '#ffc7d2',
  Primary_LightGray: '#F2F2F2',
  Primary_Violet: '#31117c',
  Primary_VioletHighlight: '#6b15d5',
  Primary_White: '#FFFFFF',
};
export enum ActionType {
  New,
  Cancel,
  Refresh,
  Edit,
  Detail,
  Delete,
  Disable,
  Undelete,
  ExportExcel,
  ExportPDF,
  Duplicate,
  Connect,
  Sync,
  Calculate,
  Toggle,
  Print,
  Filter,
  Sort,
  ShowStatus,
  ClearFilters,
  Link,
  Bookmark,
  FullScreen,
  OpenReport,
}
export enum ViewType {
  Index,
  View,
  List,
  Dashboard,
}

export enum EditionMode {
  Detail,
  Create,
  Update,
  SelectDialog,
  CreateDialog,
  UpdateDialog,
  None,
}

export enum DataType {
  Integer,
  Currency,
  Decimal,
  CheckBox,
  RadioButton,
  Date,
  DateRange,
  DateTime,
  Time,
  Switch,
  String,
  MaskedText,
  AreaText,
  DropDown,
  ComboBox,
  MultiSelect,
  MultiSelectAll,
  MultiSelectList,
  AutoComplete,
  Slider,
  Color,
  FileUpload,
  GroupGraph,
  UserGraph,
  RadioGroup,
  Guid,
  Email,
  List,
  Enum,
  IdDisplay,
  IdDisplayList,
  IdDisplayIso,
  Input
}
